export const loaderFetch = async (remote: string, brand: string, localDev = false): Promise<void> => {
  const remoteUrl = localDev ? `${remote}/${brand}/manifest.json` : `${remote}/manifest.json`;

  await fetch(remoteUrl)
    .then((response) => response.json())
    .then((manifest) => {
      const version = localDev ? manifest.buildHash : manifest.version;

      document.head.insertAdjacentHTML('beforeend', `<link rel=stylesheet href="${remote}/${brand}/main.${version}.css">`);

      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `${remote}/${brand}/main.${version}.js`;
      document.head.appendChild(s);
    });
};
